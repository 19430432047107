$(document).ready(function() {
    $(".navhamburger").click(function () {
        $(".hamburgercontent").stop().slideToggle('fast', function() {
            $('.navhamburger').toggleClass('navhamburgerclose', $(this).is(':visible'));
          });
          $(".searchcontent").not(".hamburgercontent").slideUp('fast', function() {
            $('.navsearch').toggleClass('navsearchclose', $(this).is(':visible'));
          });
        return false;
    });

    $(".navsearch").click(function () {
        $(".searchcontent").stop().slideToggle('fast', function() {
            $('.navsearch').toggleClass('navsearchclose', $(this).is(':visible'));
          });
          $(".hamburgercontent").not(".searchcontent").slideUp('fast', function() {
            $('.navhamburger').toggleClass('navhamburgerclose', $(this).is(':visible'));
          });
        return false;
    });

    $(".microsite-menu-hamburger").click(function () {
        $(".microsite-menu-items-mobile").stop().slideToggle('fast', function() {
            $('.hamburger--3dy').toggleClass('is-active', $(this).is(':visible'));
          });
          $(".hamburgercontent").not(".microsite-menu-items-mobile").slideUp('fast', function() {
            
          });
        return false;
    });

    $('.navsearch').click(function () {
      $(".custom-search-input").focus();
  })

  $('.dropdown__title').on('mousedown', function(e) {
    e.preventDefault();
    this.blur();
    window.focus();
 });

  });
  